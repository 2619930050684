<template>
  <b-form>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col
            sm="12"
            md="6"
          ><h6 class="mb-1">
            Model Ekle
          </h6></b-col>
        </b-row>
        <b-form-group label="Başlık">
          <b-form-input
            v-model="formData.title"
            placeholder="Başlık"
          />
        </b-form-group>
        <b-form-group label="Marka">
          <v-select
            v-model="formData.id_com_swap_brand"
            :options="brands"
            label="name"
            :reduce="option => option.id"
            placeholder="Seçiniz"
          />
        </b-form-group>
        <b-form-group label="Kasa Tipi">
          <v-select
            v-model="formData.id_com_car_type"
            :options="carTypes"
            label="title"
            :reduce="option => option.id"
            placeholder="Seçiniz"
          />
        </b-form-group>
        <b-form-group label="Gizli">
          <v-select
            v-model="formData.hidden"
            :options="option"
            label="title"
            :reduce="option => option.id"
            placeholder="Seçiniz"
          />
        </b-form-group>
        <b-form-group label="BCS">
          <v-select
            v-model="formData.bcs_status"
            :options="option"
            label="title"
            :reduce="option => option.id"
            placeholder="Seçiniz"
          />
        </b-form-group>
      </b-card-text>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line
import vSelect from 'vue-select'

export default {
  name: 'Add',
  components: {
    BForm,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        title: null,
        hidden: null,
        id_com_swap_brand: null,
        id_com_car_type: null,
        bcs_status: null,
      },
      option: [{ id: '1', title: 'Evet' }, { id: '0', title: 'Hayır' }],
    }
  },
  computed: {
    saveStatus() {
      const status = this.$store.getters['swapmodels/getSwapmodelSaveStatus']
      return status
    },
    brands() {
      return this.$store.getters['swapbrands/getSwapbrands']
    },
    carTypes() {
      return this.$store.getters['carType/getDataList']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          name: null,
          web: '1',
          salestatus: '1',
          servicestatus: '1',
          ordering: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getBrands()
    this.getCarTypes()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('swapmodels/swapmodelSave', this.formData)
    },
    getBrands() {
      this.$store.dispatch('swapbrands/swapbrandsList')
    },
    getCarTypes() {
      this.$store.dispatch('carType/dataList')
    },
  },
}
</script>
